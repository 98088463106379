import Vue from 'vue';
import Vuex from 'vuex';
import ZPanel from '@/components/base/panel/';
import ZTable from '@/components/base/table/';
import ZSelect from '@/components/base/z-select/';
import ZTag from '@/components/base/tag/';
import Btn from '@/components/base/btn/';
import Pagenation from '@/components/base/pagenation/';
import Modal from '@/components/base/modal/';
import { validatorsExp } from '@/assets/js/validate';

import { setTreeData } from '@/utils';

import Treeselect from '@riophae/vue-treeselect';
import '@riophae/vue-treeselect/dist/vue-treeselect.css';
const { mapGetters, mapActions } = Vuex;

export default Vue.extend({
  name: 'AdminRole',
  components: {
    ZPanel,
    ZTable,
    ZSelect,
    ZTag,
    Pagenation,
    Modal,
    Btn,
    treeselect: Treeselect,
  },
  data() {
    return {
      page: 1,
      limit: 10,
      pageTotal: 0,
      tableData: [],
      isLoading: false,
      isAddLoading: false,
      isEditLoading: false,
      isDeleteLoading: false,
      currentRow: {},
      isShowDeleteModal: false,
      isShowUserModal: false,
      editMode: 'add',
      formData: {
        menuIds: [],
        name: '',
      },
      menuList: [],
    };
  },
  computed: {
    ...mapGetters('common', {
      userInfo: 'getUserInfo',
    }),
    menuTree() {
      return setTreeData(this.menuList);
    },
    menuNameObj() {
      const menuObj = {};
      this.menuList.forEach(item => {
        menuObj[item.id] = item.name;
      });
      return menuObj;
    },
    columns() {
      return [
        {
          title: 'id',
          key: 'id',
          width: 60,
        },
        {
          title: '角色名',
          key: 'name',
          align: 'left',
        },
        {
          title: '资源',
          key: '',
          align: 'left',
          render: (h, parama) => {
            const menuIds = parama.row.menus.map(item => item.id);
            const menuNameArr = menuIds.map(id => {
              return h(
                'span',
                {
                  style: {
                    display: 'inline-block',
                    margin: '0 5px',
                  },
                },
                this.menuNameObj[id]
              );
            });

            return h('div', menuNameArr);
          },
        },
        {
          name: '操作',
          render: (h: any, parama: any) => {
            return h('div', [
              h(
                Btn,
                {
                  props: {
                    theme: 'primary',
                    size: 'small',
                  },
                  style: {
                    marginRight: '5px',
                  },
                  on: {
                    click: () => {
                      this.handleRowEdit(parama.row);
                    },
                  },
                },
                '编辑'
              ),
              h(
                Btn,
                {
                  props: {
                    theme: 'error',
                    size: 'small',
                  },
                  style: {
                    marginRight: '5px',
                  },
                  on: {
                    click: () => {
                      this.handleRowDel(parama.row);
                    },
                  },
                },
                '删除'
              ),
            ]);
          },
        },
      ];
    },
  },
  mounted() {
    this.requestRoleList();
    this.requestMenuList();
  },
  methods: {
    ...mapActions({
      toggleSignInModal: 'common/toggleSignInModal',
    }),

    /**
     * @desc 刷新
     */
    refresh() {
      this.requestRoleList();
    },

    /**
     * @desc 请求 获取用户列表
     */
    requestRoleList() {
      this.isLoading = true;
      const params = {
        page: this.page,
        pageSize: this.limit,
      };
      this.$myApi.role
        .index(params)
        .then((res: any) => {
          this.tableData = res?.data?.result || [];
          this.pageTotal = res?.data?.pageInfo?.total_pages || 0;
          this.isLoading = false;
        })
        .catch(err => {
          this.isLoading = false;
          this.$toast.error(err?.data?.msg || err?.msg || '请求异常');
        });
    },

    /**
     * @desc 请求 获取菜单列表
     */
    requestMenuList() {
      this.$myApi.menu
        .getAllMenuTree()
        .then((res: any) => {
          this.menuList = res?.data || [];
        })
        .catch(err => {
          this.$toast.error(err?.data?.msg || err?.msg || '请求异常');
        });
    },

    /**
     * @desc 处理 menu 展示
     */
    normalizer(node) {
      return {
        id: node.id,
        label: node.name,
        children: node.children,
      };
    },

    /**
     * @desc 请求 新增
     */
    requestAdd() {
      const params = {
        ...this.formData,
      };
      this.isAddLoading = true;
      this.$myApi.role
        .create(params)
        .then((res: any) => {
          this.isAddLoading = false;
          this.$toast.success('添加成功！');
          this.handleHideUserModal();
          this.requestRoleList();
        })
        .catch(() => {
          this.isAddLoading = false;
        });
    },

    /**
     * @desc 请求 修改
     */
    requestEdit() {
      const params = {
        ...this.formData,
        id: this.currentRow.id,
      };
      this.isEditLoading = true;
      this.$myApi.role
        .update(params)
        .then(() => {
          this.isEditLoading = false;
          this.$toast.success('修改成功！');
          this.handleHideUserModal();
          this.requestRoleList();
        })
        .catch(() => {
          this.isEditLoading = false;
        });
    },

    /**
     * @desc 请求 删除角色
     */
    requestDelete() {
      this.isDeleteLoading = true;
      this.$myApi.role
        .delete(this.currentRow.id)
        .then(() => {
          this.isDeleteLoading = false;
          this.$toast.success('删除成功！');
          this.handleHideDeleteModal();
          this.requestRoleList();
        })
        .catch(err => {
          console.log(err.data, 'err');
          this.isDeleteLoading = false;
          this.$toast.error(err?.data?.msg || err?.msg || '删除失败！');
        });
    },

    /**
     * @desc 分页点击
     */
    handleChangePage(page: number) {
      this.page = page;
      this.requestRoleList();
    },

    /**
     * @desc 清空表单值
     */
    handleClearFormData() {
      this.formData = {};
    },

    /**
     * @desc 回显表单值
     */
    handleRecoveryFormData(data: any) {
      this.formData = {
        name: data.name,
        menuIds: data.menus.map(item => item.id),
      };
    },

    /**
     * @desc 新增标签
     */
    handleAddUser() {
      this.editMode = 'add';
      this.handleShowUserModal();
    },

    /**
     * @desc 表格点击事件 编辑
     */
    handleRowEdit(row: any) {
      this.editMode = 'edit';
      this.currentRow = row;
      this.handleRecoveryFormData(row);
      this.handleShowUserModal();
    },

    /**
     * @desc 表格点击事件 删除
     */
    handleRowDel(row: any) {
      this.currentRow = row;
      this.handleShowDeleteModal();
    },

    /**
     * @desc 检查表单数据是否合格
     */
    handleCheckFormData() {
      const { name, menuIds } = this.formData;
      if (!name) {
        this.$toast.error('请填写橘角色名称！');
        return false;
      }
      if (!menuIds) {
        this.$toast.error('请勾选权限列表！');
        return false;
      }
      return true;
    },

    /**
     * @desc 验证是否已登录，是否为 admin 用户
     */
    handleValidateUserAuth() {
      let isUserAuth = false;
      if (this.userInfo) {
        if (this.userInfo.userName === 'admin') {
          isUserAuth = true;
        } else {
          this.$toast.error('非admin，无权限！');
        }
      } else {
        this.$toast.info('请登录');
        this.toggleSignInModal(true);
      }
      return isUserAuth;
    },

    /**
     * @desc 提交
     */
    handleSubmit() {
      if (!this.handleCheckFormData()) {
        return;
      }
      // if (!this.handleValidateUserAuth()) {
      //   return;
      // }

      if (this.editMode === 'edit') {
        this.requestEdit();
      } else {
        this.requestAdd();
      }
    },

    /**
     * @desc 显示标签表单弹框
     */
    handleShowUserModal() {
      this.isShowUserModal = true;
    },

    /**
     * @desc 隐藏标签表单弹框
     */
    handleHideUserModal() {
      this.isShowUserModal = false;
      this.handleClearFormData();
    },

    /**
     * @desc 显示删除标签弹框
     */
    handleShowDeleteModal() {
      this.isShowDeleteModal = true;
    },

    /**
     * @desc 隐藏删除标签弹框
     */
    handleHideDeleteModal() {
      this.isShowDeleteModal = false;
    },
  },
});
